'use strict';

class LoginController {
  constructor(Auth, $state, $rootScope) {
    this.user = {};
    this.errors = {};
    this.submitted = false;

    this.Auth = Auth;
    this.$state = $state;
    this.$rootScope = $rootScope;

    this.redirectUser(Auth.getCurrentUser());
  }

  login(form) {
    this.submitted = true;
    if (form.$valid) {
      this.Auth.login({
        username: this.user.username,
        password: this.user.password
      })
      .then(() => {
        this.$rootScope.sideMenuShowing = true;
        this.redirectUser(this.Auth.getCurrentUser());
      })
      .catch(err => {
        this.errors.other = err.message;
      });
    }
  }

  redirectUser(user) {
    if (user.role === 'admin') {
      this.$state.go('dashboard');
    } else if (user.role === 'shipper') {
      this.$state.go('shipping/exterior');
    } else if (user.role === 'productionManagerIn' || user.role === 'productionManagerOut') {
      this.$state.go('production');
    } else if (user.role === 'receptionist') {
      this.$state.go('dashboard');
    }
  }

}

angular.module('cpformplastApp')
  .controller('LoginController', LoginController);
